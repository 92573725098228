body {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 12px;
}

.event-list {
  cursor: pointer;
}
.event-content {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: hidden scroll;
}
.event-title {
  font-size: 0.8rem;
}
.event-title.unread {
  font-weight: bold;
}
.event-description {
  color: #666;
  overflow: hidden;
}
.eventDashboardContainer {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 100px;
  bottom: 0;
}
.eventFilterSetContainer {
  height: calc(50vh - 100px);
  float: left;
}
.eventListContainer {
  height: calc(50vh - 100px);
  overflow: hidden scroll;
}
.eventContentContainer {
  height: 50vh;
  overflow: hidden scroll;
  position: relative;
}
.eventDashboardTopRow {
  border-bottom: 3px solid #333;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.eventFilterSetContainer .nav .nav-item.active {
  font-weight: bold;
}

.event-list > li.list-group-item {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.event-list > li.list-group-item a {
  color: #333;
}
.event-list > li.list-group-item a.active {
  color: #333;
  background-color: #ccc;
}
.event-list > li.list-group-item.bg-coloured a {
  color: #fff;
}
.event-list > li.list-group-item:hover {
  background-color: #ddd;
}

.event-list tr > td {
  padding: 0;
}
.event-list tr > td a {
  color: #333;
  display: block;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  text-decoration: none;
}
.event-list tr > td:first-child a {
  padding-left: 0.25rem;
}
.event-list tr > td:last-child a {
  padding-right: 0.25rem;
}

.pointerable {
  cursor: pointer;
}

.event-list td.date-cell {
  text-align: right;
  white-space: nowrap;
}

tr.event-label,
tr.event-label td a {
  color: #333;
}
tr.event-label.active,
tr.event-label.active td a {
  background-color: #bbb;
}
tr.event-label.event-red td,
tr.event-label.event-red td a {
  color: #f00;
}
tr.event-label.event-red.active,
tr.event-label.event-red.active td a {
  background-color: #f00;
  color: #fff;
}
tr.event-label.event-yellow td,
tr.event-label.event-yellow td a {
  color: #ff9800;
}
tr.event-label.event-yellow.active,
tr.event-label.event-yellow.active td a {
  background-color: #ff9800;
  color: #333;
}
tr.event-label.event-green td,
tr.event-label.event-green td a {
  color: #009800;
}
tr.event-label.event-green.active,
tr.event-label.event-green.active td a {
  background-color: #009800;
  color: #fff;
}
tr.event-label.event-blue td,
tr.event-label.event-blue td a {
  color: #3333e6;
}
tr.event-label.event-blue.active,
tr.event-label.event-blue.active td a {
  background-color: #3333e6;
  color: #fff;
}

@media (max-width: 400px) {
  .container {
    padding: 0 !important;
  }
  .modal .modal-title {
    word-break: break-word;
  }
  .event-content-inner {
    overflow: scroll;
  }
  .modal button.close {
    font-size: 4rem;
  }
  .eventDashboardContainer {
    overflow-y: scroll;
    padding-left: 5px;
    padding-right: 5px;
  }
  .App > nav {
    padding: 0.25rem;
  }
}
